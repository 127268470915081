import React, { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const RootLayout = lazy(() => import('../layout/RootLayout'));
const VerifyCode = lazy(() => import('../pages/Kiosk/VerifyCode'));
const UpsStock = lazy(() => import('../pages/Kiosk/UpsStock'));
const UpsPickup = lazy(() => import('../pages/Kiosk/UpsPickup'));
const UpsReturn = lazy(() => import('../pages/Kiosk/UpsReturn'));
const SecureBadgeLogin = lazy(() => import('../pages/Admin/SecureBadgeLogin'));
const AdminLogin = lazy(() => import('../pages/Admin/AdminLogin'));
const AdminPanel = lazy(() => import('../pages/Admin/AdminPanel'));
const AdminSetting = lazy(() => import('../pages/Admin/AdminSetting'));
const AdminMaintenance = lazy(() => import('../pages/Admin/AdminMaintenance'));
const AdminForgotPassword = lazy(() => import('../pages/Admin/AdminForgotPassword'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<RootLayout />}>
        <Route path="/" element={<VerifyCode />} />
        <Route path="/upsStock" element={<UpsStock />} />
        <Route path="/upsPickup" element={<UpsPickup />} />
        <Route path="/upsReturn" element={<UpsReturn />} />
        <Route path="/securebadge" element={<SecureBadgeLogin />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
        <Route path="/adminSetting" element={<AdminSetting />} />
        <Route path="/adminMaintenance" element={<AdminMaintenance />} />
        <Route path="/forgetPassword" element={<AdminForgotPassword />} />
      </Route>
    </Route>,
  ),
);

export default router;
