import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import router from './router/router';
import './index.css';
import Loader from './components/fromComponents/Loader';
import sagaActions from './sagas/sagaActions';
import API from './common/axios';
import { updateData } from './redux/kioskSlice';
import {
  openMiniBins, readSettings, writeInitSettings, WriteInitPickupInfo,
} from './utils/board';
import { syncMapping } from './common/mini24_bins_mapping';
import BOX_TYPE from './constant/boxTypes';

function App() {
  const FileObje = {
    boxId: '',
    dynamicDataSource: false,
    rearStock: false,
    IsActive: true,
    pickupType: '1',
    steps: [
      {
        step: 0,
        name: 'Touch to Start',
        value: true,
        prev: '',
        next: '',
        btnClick: 0,
        stepTitle: 0,
      },
      {
        step: 1,
        name: 'Pick up Code Enter',
        value: true,
        prev: '/',
        next: '/KioskVerify',
        btnClick: 0,
        stepTitle: 1,
      },
      {
        step: 2,
        name: 'DOB verification',
        value: true,
        prev: '/KioskPickupCode',
        next: '/KioskConfirmPickup',
        btnClick: 0,
        stepTitle: 2,
      },
      {
        step: 3,
        name: 'Confirm Pick up items',
        value: true,
        prev: '/KioskVerify',
        next: '/KioskConsult',
        btnClick: 0,
        stepTitle: 3,
      },
      {
        step: 4,
        name: 'Consult',
        value: true,
        prev: '/KioskConfirmPickup',
        next: '/KioskSignature',
        btnClick: 0,
        stepTitle: 4,
      },
      {
        step: 5,
        name: 'Signature',
        value: true,
        prev: '/KioskConsult',
        next: '/KioskCheckConfirmuser', // Modified By Belani Jaimin
        btnClick: 0,
        stepTitle: 5,
      },
      {
        step: 6,
        name: 'DL Capture',
        value: true,
        prev: '/KioskSignature',
        next: '/KioskCollectMedicines',
        btnClick: 0,
        stepTitle: 6,
      },
      {
        step: 7,
        name: 'Collect your items',
        value: true,
        prev: '',
        next: '/KioskFeedback',
        btnClick: 0,
        stepTitle: 7,
      },
      {
        step: 8,
        name: 'Survey',
        value: true,
        prev: '',
        next: '/',
        btnClick: 0,
        stepTitle: 8,
      },
      {
        step: 9,
        name: 'Confirm Pickup Payment',
        value: true,
        prev: '/KioskVerify',
        next: '/KioskConsult',
        btnClick: 0,
        stepTitle: 9,
      },
      {
        step: 10,
        name: 'View Current Rx',
        value: true,
        prev: '/KioskPatientViewRx',
        next: '/KioskConsult',
        btnClick: 0,
        stepTitle: 0,
      },
    ],
    posWaitDuration: 3000,
    IsNDCVideoAllow: false,
    /*
      Added By : Belani Jaimin
      Description: Send payment request to ingenico device
      Note: The default URL is base url of kiosk-wrapper code configuration.
    */
    paymentBaseUrl: '',
    isHWCommuniocationEnabled: true,
    EnableLifeboat: false,
  };
  const dispatch = useDispatch();
  let socket;

  const getBoxesData = async () => {
    let boxId;
    let response;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
      response = await API.get(`allinone/${boxId}`).then((res) => {
        dispatch(updateData(res.data && res.data.box));
        localStorage.setItem('typeId', res?.data?.box?.type_id);
        localStorage.setItem('is_install_mode', (res?.data?.box?.is_install_mode) || false);
        return res.data;
      }).catch((err) => console.log(err));
    }
    return response;
  };

  const initSocket = async () => {
    const _token = localStorage.getItem('authTokens');
    const decodedToken = _token && atob(_token);
    if (decodedToken && decodedToken.length > 21) {
      socket = io(process.env.REACT_APP_VIDEO_SOCKET_BASEURL, {
        transports: ['websocket'],
        auth: {
          token: `Bearer ${decodedToken}`,
        },
        secure: true,
      });
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@     Socket init from App.js      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      if (socket) {
        socket.on('connect', () => {
          console.log('Socket connection established from PORTAL(ReConnect)');
        });

        socket.emit('ConfigureBoxId', {
          box_id: localStorage.getItem('boxId'),
        });

        socket.on('KioskOpenBin', async (payload) => {
          const boxDetails = await getBoxesData();
          const boxType = ![undefined, 'undefined', null, 'null', ''].includes(boxDetails) ? boxDetails.type_id : localStorage.getItem('typeId');
          if (boxType === BOX_TYPE.M_24) {
            console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', payload);
            const bin_id = syncMapping(payload.bin);
            openMiniBins(bin_id);
          }
        });

        socket.on('connect_error', (error) => {
          console.log('Connect Error: ', error);
          if (
            error.message === 'TokenExpiredError'
            || error.message === 'JsonWebTokenError'
            || error.message === 'NoTokenFound'
          ) {
            console.log('Token issue!');
          }
        });

        socket.on('connect_failed', (error) => {
          console.log('Connect Failed: ', error);
        });

        socket.on('error', (error) => {
          console.log('Error: ', error);
        });

        socket.on('disconnect', (reason) => {
          console.log(
            `Socket Disconnected From Portal and Reason is: ${reason}`,
          );
        });
      }
    } else {
      console.log('***initSocket*** Not a valid token for initializing socket connection!');
    }
  };

  useEffect(() => {
    if (window.electron) {
      const initialPickupObject = {
        message: 'Initialize a file for logs',
        timestamp: new Date(),
      };
      writeInitSettings(FileObje);
      setTimeout(() => {
        WriteInitPickupInfo(initialPickupObject);
        getBoxesData();
        initSocket();
        dispatch({ type: sagaActions.FETCH_BOX });
      }, 1000);
    }
  }, []);

  return (
    <Suspense
      fallback={(
        <div className="flex justify-center align-middle h-screen">
          <Loader />
        </div>
      )}
    >
      <RouterProvider router={router}>
        {/* Components will be rendered here */}
      </RouterProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
        style={{ width: '100%', fontSize: '22px', padding: '20px' }}
      />
    </Suspense>
  );
}

export default App;
